/* Importing Partials CSS */
@import url("./partials/__partials.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Overriding Global CSS */

html {
  scroll-behavior: smooth;
}

/* Custom CSS */

.debug {
  border: 3px solid red;
}
